<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
 
      <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     
      
          <v-row class="mx-auto mt-0 ml-0 pa-1">                                    
           <v-col offset="0" cols="12" xl="2" lg="4" md="3" sm="3" xs="6">
             <v-text-field
                transition="fab-transition"
                ref="nome"
               
                v-model="dados.nome"
                name="nome"
                @keyup.enter="mudaCampo('razao')"
                :name="`nome_${Math.random()}`"
                label="Nome"                
                value
                filled
                
                clearable
                hide-details
              ></v-text-field>
            </v-col>

            <v-col   v-show="cnpjShow" offset="0" cols="12" xl="4" lg="4" md="3" sm="4" xs="6">
             <v-text-field
                transition="fab-transition"
                ref="razao"               
                v-model="dados.razao_social"
                name="razao"
                :name="`razao_${Math.random()}`"
                label="Razão Social"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
             <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="6">
                <v-radio-group
                   v-model="dados.flg_tipo_pessoa"
                   hide-details                   
                   
                  row
                >
                <v-spacer></v-spacer>
                  <v-radio
                    label="Fisica"
                    value="F"
                    
                  ></v-radio>
                  <v-radio
                    label="Juridica"
                    value="J"
                    
                  ></v-radio>
                </v-radio-group>
                
            </v-col>
          </v-row>
            <v-row class="mx-auto mt-0 ml-0 pa-1">
            
           
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="cpf"
                v-show="cpfShow"
                v-model="dados.cpf_cnpj"
                name="cpf"
                v-mask="'###########'"               
               
                :name="`cnpfj_${Math.random()}`"
                label="CPF"                
                value
                filled                
                clearable                
              ></v-text-field>
               <v-text-field
                transition="fab-transition"
                ref="cnpj"
                v-show="cnpjShow"
                v-model="cnpj"
                name="cnpj"
                v-mask="'##############'"
                :name="`cnpfj_${Math.random()}`"
                label="CNPJ"                
              
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          
              <v-col v-show="cnpjShow" cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="ie"
               
                v-model="dados.ie"
                :name="`ie_${Math.random()}`"
                name="ie"
                label="Inscrição Estadual"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
             <v-col v-show="!cnpjShow" cols="12" xl="4" lg="2" md="2" sm="2" xs="2">
              
                <v-checkbox
                 v-model="dados.produtor_rural"
                  label="Produtor Rural"
                  hide-details
                ></v-checkbox>
            </v-col>
             <v-col v-show="!cnpjShow" cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="cpf_produtor"
                v-mask="'###########'"
                v-model="dados.cpf_produtor"
                name="cpf_produtor"
                label="CPF Produtor"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            </v-row>
            <v-row class="mx-auto mt-0 ml-0 pa-1">
             <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="cep"
                 v-mask="'########'"
                v-model="dados.cep"
                name="cep"
                label="Cep"                
                value
                filled
                :loading="carregaCep"                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            
           
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="endereco"
               
                v-model="dados.endereco"
                name="endereco"
                label="Endereço"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
           
       
            
            <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="cidade"
               
                v-model="dados.cidade"
                :name="`cidade_${Math.random()}`"
                name="cidade"
                label="Cidade"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>´
             <v-col cols="12" xl="3" lg="1" md="3" sm="3" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="uf"
               
                v-model="dados.uf"
                :name="`uf_${Math.random()}`"
                name="uf"
                label="UF"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
              </v-row>
              <v-row class="mx-auto mt-0 ml-0 pa-1">
              <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="telefone"               
                v-model="dados.telefone"
                :name="`telefone_${Math.random()}`"
                name="telefone"
                label="Telefone"    
                  v-mask="'###########'"            
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="celular"               
                v-model="dados.celular"
                :name="`celular_${Math.random()}`"
                name="celular"
                label="Celular"
                 v-mask="'###########'"        
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
              <v-col cols="12" xl="3" lg="3" md="4" sm="4" xs="12">
              <v-text-field
                transition="fab-transition"
                ref="email"               
                v-model="dados.email"
                :name="`email_${Math.random()}`"
                name="email"
                label="Email"                
                value
                filled                
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            </v-row>            
            
              <v-row class="mx-auto mt-0 ml-0 pa-5">
             <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                small
                @click="salvar()"
                color="success"
              >
                  <v-icon left>
                 mdi-content-save-outline
                </v-icon>
                 Salvar
              </v-btn>
            </v-col>
             <v-col v-show="editar" cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
               <v-btn
                small
                @click="cancelEdit()"
                color="warning"
              >
                  
                 Cancelar
              </v-btn>
            </v-col>
          </v-row>
          
      
    </v-card>
      <v-card class="mt-5">
     <v-row no-gutters>
      <v-col style="padding:6px 0px 6px 0px!important" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
      
    
    
      <v-data-table
        :headers="headers"
        :items="lista"
        :loading="false"
        :search="search"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
     
       <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
       
      </v-data-table>  
   
    
     </v-col>
    </v-row>
    </v-card>
    </div>
     <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-main>
  
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  props: {
    source: String,
  },
  directives: { mask },
  mounted() {    
    this.mudaCampo("nome");
    this.buscaLista();
  },
 
  data: () => ({
    selPessoa:'J',
    cnpjShow : true,
    cpfShow : false,
    mostraMsg:false,
    textSnack:"",
    corMsg:"",
    search: "",
    carregaCep:false,
    contador:[1,2,3,4,5,6],
    dados:{
      nome:"",
      razao_social:"",
      flg_tipo_pessoa:"J",
      
      cpf_cnpj:"",
      ie:"",
      chk_produtor_rural:false,
      cpf_produtor_rural:"",
      endereco:"",
      cep:"",
      uf:"",
      cidade:"",
      telefone:"",
      celular:"",
      email:""
     
    },
    listaIndent:[{
      valor:'D',
      descricao:"Débito"
    },    
    {
      valor:'C',
      descricao:"Crédito"
    }],
    items: [],
    headers: [],
    itemsBread: [
      {
        text: "Cadastros",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Fornecedores",
        disabled: false,
        href: "/dashboard/fornecedores",
      },
    ],
    dialog: false,
    grupos:[],
    editar:false,
    lista:[],
       headers: [
    
      {
        text: "Nome",
        value: "nome",
      },
      {
        text: "Cpf/Cnpj",
        value: "cpf_cnpj",
      },
       {
        text: "Celular",
        value: "celular",
      },
    
      { text: "Ações", value: "actions", sortable: false },
    ],
    selDel:"",
    selEdit:"",
    dialogDelete:false,
    cnpj:""
  }),
   watch: {
  "dados.cep"(val) {
      if (this.dados.cep.length == 8) {
          this.carregaCep = true;
          this.buscaCep(val);
          this.mudaCampo('telefone')       
      }
    },
   
    "cnpj"(val  ){
      this.dados.cpf_cnpj = val
      if (this.cnpj.length == 14) {
         
         this.mudaCampo('ie')
       } 
    },
    "dados.flg_tipo_pessoa"(val){
        if(val =='J'){
          this.cnpjShow = true
          this.cpfShow = false
        }else{
          this.cnpjShow = false
          this.cpfShow = true  
        }
    },

  },
  methods:{
     cancelEdit(){
       this.editar = false;
       this.limpaCampos()
       this.buscaLista();

     },
    mudarStatus(id,status){

        axios
        .post(
          `/fin/grupo/status`,
          { id: id,chk_status:status, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
        
          if(status){
            this.textSnack = "Ativado com sucesso !";
            this.corMsg = "success";
          }else{
            this.textSnack = "Desativado com sucesso!";
            this.corMsg = "error";
          }
          
        
          this.mostraMsg = true;
          
          //this.grupos = response.data;
        });
    },
     editItem(item){
       this.editar = true
       this.dados = item;
     },
     deleteItemConfirm() {
    //  this.listaLancamentos.splice(this.editedIndex, 1);
      this.dialogDelete = false;
      axios
        .post(
          `/fin/fornecedor/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
          this.buscaLista();
          this.textSnack = "Exclusão feito com sucesso!";
        
          this.mostraMsg = true;
          this.corMsg = "success";
          //this.grupos = response.data;
        });
    },
    limpaCampos(){
      this.dados.id_fornecedor =""
      this.dados.razao_social = "",
      this.dados.nome=""
      this.dados.flg_tipo_pessoa="J",
      this.dados.cpf_cnpj="",
     
      this.dados.ie="",
      this.dados.chk_produtor_rural=false,
      this.dados.cpf_produtor_rural="",
      this.dados.endereco="",
      this.dados.cep="",
      this.dados.uf="",
      this.dados.cidade="",
      this.dados.telefone="",
      this.dados.celular="",
      this.dados.email  =""
    
    },
    deleteItem(item) {
      
      this.selDel = item.id_fornecedor;
      this.dialogDelete = true;
    },
    mudaCampo(campo) {
      eval("this.$refs." + campo + ".focus()");
    },
    validacao() {

      if (this.dados.nome == "") {
        this.textSnack = "Informe o nome";
        this.mudaCampo("nome");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
     
      if(this.dados.flg_tipo_pessoa =='F'){
        if (this.dados.cpf == "") {
          this.textSnack = "Informe o CPF!";
          this.mudaCampo("cpf");
          this.mostraMsg = true;
          this.corMsg = "error";
          return false;
        }
        
      }
       if(this.dados.flg_tipo_pessoa =='J'){
        
        if (this.dados.razao_social == "") {
          this.textSnack = "Informe Razão social!";
          this.mudaCampo("razao");
          this.mostraMsg = true;
          this.corMsg = "error";
          return false;
        }
        if (this.dados.cpf_cnpj == "") {
          this.textSnack = "Informe o CNPJ!";
          this.mudaCampo("cnpj");
          this.mostraMsg = true;
          this.corMsg = "error";
          return false;
        }
        
      } 
       if (this.dados.celular == "") {
        this.textSnack = "Informe o Celular";
        this.mudaCampo("celular");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }   
       
      return true
     },
   
    buscaCep(cep) {
      this.$viaCep.buscarCep(cep).then(obj => {
        if (obj.erro) {
          this.cep = "";
          this.textSnack = "Cep não existe!";
          this.snackbar = true;

          this.carregaCep = false;
        } else {
          this.carregaCep = false;
          this.dados.endereco =  obj.logradouro +' '+obj.bairro;
          this.dados.cidade =obj.localidade;
          this.dados.uf = obj.uf;
          
        }
      });
    },
    buscaLista() {
      axios
        .post(
          `/fin/fornecedor/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
        
        )
        .then((response) => {
          this.lista = response.data;
        });
    },
    salvar(){
        if(this.dados.flg_tipo_pessoa=='J'){
          this.dados.cpf_cnpj = this.cnpj
        }
        if (!this.validacao()) {
          
          return false;
        }
        axios
        .post(
          `/fin/fornecedor`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            dados: this.dados,
          }
          
        )
        .then((response) => {
        
        
          this.textSnack = "Gravado com sucesso!";
          this.mostraMsg = true;
          this.corMsg = "success";
         
          this.mudaCampo("nome");
          this.limpaCampos();    
         
          this.buscaLista();
        })
        .catch((e) => {
          this.loading = false;
          
          if (!Array.isArray(e.response.data)) {
          
            let itens = [];
            for (var prop in e.response.data) {
              itens.push(e.response.data[prop][0]);
            }
         

            this.textSnack = itens.join("");
            this.mostraMsg = true;
            this.corMsg = "error";
          } else {
         
            this.textSnack = e.response.data;

            this.mostraMsg = true;
            this.corMsg = "error";
          }
        });


    }
  }
};
</script>
